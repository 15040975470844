import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateAccordionItem } from '../../redux/slices/customerSlice';
/**
 * Custom hook for handling 'updatedAlarmCount' socket messages and updating local state
 */
export const useSocketUpdatedAlarmSummary = (socket, setAlarmSummaryList, recentSearchId) => {
  
  useEffect(() => {
    if (!socket) return; // Early return if socket is not available
  
      // Handle socket connection errors
      socket.on("connect_error", (error) => {
        // Optionally retry connection or notify the user
        console.error("Socket connection error:", error);
      });
    // Handle socket connection errors
    const handleConnectionError = (error) => {
      console.error("Socket connection error:", error);
    };
    // Handler for the updated alarm count from socket
    const handleUpdatedAlarmSummary = (message) => {
      try {
        const parsedMessage = typeof message === 'string' ? JSON.parse(message) : message;
        setAlarmSummaryList(parsedMessage);
      } catch (error) {
        console.error("Error parsing the message:", error);
      }
    };
  
      // Remove all existing listeners
      socket.removeAllListeners('updatedAlarmData');
      socket.removeAllListeners('connect_error');
      socket.removeAllListeners('alarm-summary-disconnect');

      // Set up new listeners
      socket.on('connect_error', handleConnectionError);
      socket.on('updatedAlarmData', handleUpdatedAlarmSummary);

      // Connect with new recentSearchId
      socket.emit('alarm-summary-connected', recentSearchId);

    
    // Clean up socket listener on unmount or when socket changes
    return () => { 
      // Disconnect from current searchId before cleanup
      socket.emit('alarm-summary-disconnect', recentSearchId);
    
      // Remove all listeners
      socket.removeAllListeners('updatedAlarmData');
      socket.removeAllListeners('connect_error');
      socket.removeAllListeners('alarm-summary-disconnect');
    };
  }, [socket, setAlarmSummaryList, recentSearchId]); // Remove accordionList dependency if not directly used
  
 
}; 

export const useSocketUpdatedAlarmLog = (socket, setAlarmLogList, recentSearchId) => {
  
   
  useEffect(() => {
    if (!socket) return; // Early return if socket is not available
  
    // Handle socket connection errors
    socket.on("connect_error", (error) => {
      // Optionally retry connection or notify the user
      console.error("Socket connection error:", error);
    });

    // Handle socket connection errors
    const handleConnectionError = (error) => {
      console.error("Socket connection error:", error);
    };
    // Handler for the updated alarm count from socket
    const handleUpdatedAlarmLog = (message) => {
      try {
        const parsedMessage = typeof message === 'string' ? JSON.parse(message) : message;
        setAlarmLogList(parsedMessage);
      } catch (error) {
        console.error("Error parsing the message:", error);
      }
    };
  
   // Remove all existing listeners
   socket.removeAllListeners('fetchLatestAlarmLogs');
   socket.removeAllListeners('connect_error');
   socket.removeAllListeners('alarm-log-disconnect');
 
   // Set up new listeners
   socket.on('connect_error', handleConnectionError);
   socket.on('fetchLatestAlarmLogs', handleUpdatedAlarmLog);
 
   // Connect with new recentSearchId
   socket.emit('alarm-log-connected', recentSearchId);
   
    // Clean up socket listener on unmount or when socket changes
    return () => {
     
    // Remove all listeners
    socket.removeAllListeners('fetchLatestAlarmLogs');
    socket.removeAllListeners('connect_error');
    socket.removeAllListeners('alarm-log-disconnect');

    };
  }, [socket, setAlarmLogList, recentSearchId]); // Remove accordionList dependency if not directly used

}; 


export const useSocketAlarmCountOnLandingPage = (socket,updateAccordionList,accordionList, recentSearchId) => {  
  const dispatch = useDispatch();
  const { t } = useTranslation(); // Initialize translation hook
  const { shortCuts } = t('mainContainer').DateRangeSelection; // Get shortcut labels from translation
  useEffect(() => {
    if (!socket) return; // Early return if socket is not available
        // Handle socket connection errors
     socket.on("connect_error", (error) => {
           // Optionally retry connection or notify the user
        console.error("Alarm count socket connection error:", error);
      });
    if (recentSearchId && shortCuts["Today"]) {
          // Handler for the updated alarm count from socket
      const handleUpdatedAlarmCount = (socketData) => { 
      try {
        // Map over accordionList to find the item that matches the siteId and update electricity
        const updatedAccordionItem = accordionList?.find(accordionItem => accordionItem?.siteId === socketData?.siteId);
           
        if (updatedAccordionItem) { 
            // Create the updated item
            const updatedItem = {
              ...updatedAccordionItem,
              alarmCount: socketData.alarmCount
            }; 
            // Dispatch the updated accordionItem (not the whole list)
            dispatch(updateAccordionItem(updatedItem)); 
   
         };
       } catch (error) {
         console.error("Error parsing the alarm count socket data:", error);
       }
    }; 



     // Remove all existing listeners
     socket.removeAllListeners('updatedAlarmCount');
     socket.removeAllListeners('connect_error');
     socket.removeAllListeners('alarm-count-disconnect');
     
      socket.emit('alarm-count-connected', recentSearchId);
      socket.on('updatedAlarmCount',handleUpdatedAlarmCount)
      // Clean up socket listeners on component unmount
      return () => { 
        // Remove all listeners
      socket.removeAllListeners('updatedAlarmCount');
      socket.removeAllListeners('connect_error');
      socket.removeAllListeners('alarm-count-disconnect'); 
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket,accordionList]);
 
}; 

export const useSocketElectricityOnLandingPage = (socket, updateAccordionList,  DateRangeFilter, accordionList, recentSearchId) => {  
  const dispatch = useDispatch();
  const { t } = useTranslation(); // Initialize translation hook
  const { shortCuts } = t('mainContainer').DateRangeSelection; // Get shortcut labels from translation
  useEffect(() => {
    if (!socket) return; // Early return if socket is not available

    // Handle socket connection errors
    socket.on("connect_error", (error) => {
      console.error("Energy socket connection error:", error);
    });

    // Check if recentSearchId is available and Today is selected in the calendar
    if (recentSearchId && shortCuts["Today"]) {
      // Handler for the updated electricity count from socket
      // Handler for the updated electricity count from socket
      const handleUpdatedElectricityCount = (socketData) => {
        try {
          if (socketData && socketData?.siteId) { 
            // Map over accordionList to find the item that matches the siteId and update electricity
            const updatedAccordionItem = accordionList?.find(accordionItem => accordionItem?.siteId === socketData?.siteId);
          
            if (updatedAccordionItem) {
              // Make sure both values are valid numbers
              const currentElectricity = Number(updatedAccordionItem?.electricity);
              const incomingElectricity = Number(socketData?.electricity);
          
              // Ensure electricity values are valid before updating
              if (!isNaN(currentElectricity) && !isNaN(incomingElectricity)) {
                const newElectricityValue = currentElectricity + incomingElectricity;
          
                // Create the updated item
                const updatedItem = {
                  ...updatedAccordionItem,
                  electricity: newElectricityValue
                }; 
                // Dispatch the updated accordionItem (not the whole list)
                dispatch(updateAccordionItem(updatedItem));
              } else {
                console.error('Invalid electricity values:', updatedAccordionItem?.electricity, socketData?.electricity);
              }
            }
          }
          
        } catch (error) {
          console.error("Error parsing the energy socket data:", error);
        }
      }; 
  
      // Remove all listeners
      socket.removeAllListeners('connect_error');
      socket.removeAllListeners('electricity-connected'); 
      socket.removeAllListeners('electricity-disconnect');
      
      socket.emit('electricity-connected', recentSearchId);
      socket.on('fetchRecentEnergyConsumption', handleUpdatedElectricityCount);

      // Clean up socket listeners on component unmount
      return () => {
        socket.removeAllListeners("fetchRecentEnergyConsumption", handleUpdatedElectricityCount); 
         // Remove all listeners
        socket.removeAllListeners('connect_error');
        socket.removeAllListeners('electricity-connected'); 
        socket.removeAllListeners('electricity-disconnect');
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, accordionList, DateRangeFilter, recentSearchId, dispatch]);
};


export const useSocketDeviceStatusSettingPage = (socket, setLocalSiteList, localSiteList, siteListStore, currentPage, siteScreenType) => {
  useEffect(() => {
    if (!socket) return; // Early return if socket is not available

    // Handle socket connection errors
    socket.on("connect_error", (error) => {
      console.error("Energy socket connection error:", error);
    });
     // We are filtering the sites here to avoid checking all the sites in the list.
     // This ensures we only work with the necessary sites that needs to be updated.
     const matchingData = localSiteList?.filter(site =>
      siteListStore?.some(localSite => localSite?.id === site?.id)
    );

    // Handler for the updated device status
    const handleUpdatedDeviceStatus = (socketData) => {
         try {
        if (socketData && socketData?.siteId) {
         // Map over matchingData and update the device status for the matching siteId.
            const updatedSiteList =matchingData?.length && matchingData?.map((item) => {
                if (item?.id === socketData?.siteId) {
                    const newDeviceStatus = socketData?.deviceStatus;
                    return {
                        ...item,
                        deviceStatus: newDeviceStatus, // Update the device status
                    };
                }
                return item; // Return the item unchanged if it doesn't match
            });

            // Check if any changes were made in device status
            const hasUpdates = updatedSiteList?.some((item, index) => {
                return item?.deviceStatus !== matchingData[index]?.deviceStatus;
            });

            // Update state only if there are changes in deviceStatus
            if (hasUpdates) {
                setLocalSiteList(updatedSiteList); // Update the state with the new list
            }
        }
    }      
      catch (error) {
        console.error("Error parsing the energy socket data:", error);
      }
    };

    // Emit a message to initiate the connection for each siteId
    matchingData?.forEach((item) => {
      socket.emit("device-status-connected", item?.id);
    });

    // Set up the listener for device status updates
    socket.on("fetchDeviceStatus", handleUpdatedDeviceStatus);

    // Clean up socket listeners on component unmount
    return () => {
      socket.off("fetchDeviceStatus", handleUpdatedDeviceStatus);
      socket.off("connect_error");
      matchingData.forEach((item) => {
        socket.off("device-status-connected");
      });
      socket.off("device-status-disconnect")
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, currentPage ,localSiteList, siteScreenType]);
};
