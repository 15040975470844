import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useSelector } from "react-redux";
import { ReactComponent as SearchIcon } from '../../assets/images/SearchIcon.svg';
import CustomButton from '../buttons/CustomButton';
import { ReactComponent as FilterIcon } from '../../assets/images/FilterIcon.svg';
import { ReactComponent as AddIcon } from '../../assets/images/SmallAddIcon.svg';
import TableActions from '../customTable/TableActions';

const FilterSearch = props => {
	const {
		isSiteAndCustAvail,
		type, 
		searchVisible,
		handleClear,
		handleSearchChange,
		handleAddCustomer,
		enteredText,
		refToTop,
		sortOptions,
		handleActionSelection,
		btnText = 'Add customer',
		searhButtonWidth = '180px',
		boxPadding = '',
		addButtonWidth = '',
		boxGap,
		customFlexStyle = '',
		buttonMarginLeft,
		searchPlaceholder,
		isSite
	} = props;
	const { customersList } = useSelector(
		(state) => state?.customerInfo
	  );
	const getSearchInputProps = onClose => ({
		startAdornment: (
			<InputAdornment
				position="start"
				sx={{
					'& .MuiSvgIcon-root': {
						padding: '10px',
					},
				}}
			>
				<SearchIcon />
				{enteredText.length > 0 && (
					<span
						className="close"
						onClick={() => onClose()}
						style={{ cursor: 'pointer', marginRight: '1rem' }}
					>
						&times;
					</span>
				)}
			</InputAdornment>
		),
	});

	return (
		<Stack direction="column" spacing={1} marginTop={'8px'}>
		<Box
				ref={refToTop}
				sx={{
					display: 'flex',
					'& .MuiFormControl-root': {
						width: searhButtonWidth,
						height: '40px',
					},
					alignItems: 'center',
					padding: boxPadding,
					gap: boxGap,
				}}
			>
		{ !(type==="siteSearch"&&!searchVisible)&&
			(<>
			{(!(customersList.length==0&&type==="siteSearch")&&isSiteAndCustAvail)&&<TextField
					id="search"
					name="search"
					value={enteredText}
					placeholder={searchPlaceholder}
					autoComplete="off"
					sx={{
						flex: customFlexStyle,
						'& .MuiInputBase-root': {
							fontWeight: '400',
							fontSize: '14px',
							lineHeight: '18px',
							letterSpacing: '0.16px',
							height: '40px',
							backgroundColor: '#F4F4F4',
						},
					}}
					onChange={e => handleSearchChange(e)}
					InputProps={getSearchInputProps(handleClear)}
				/> }
				{/* <div
					style={{
						marginLeft: '14px',
						marginRight: '14px',
						alignSelf: 'center',
					}}
				>
					<TableActions
						options={sortOptions}
						handleActionSelection={handleActionSelection}
						actionIcon={<FilterIcon />}
					/>
				</div> */}
				</>)}

				<CustomButton
					variant="contained"
					onClick={handleAddCustomer}
					icon={<AddIcon />}
					width={addButtonWidth}
					height="40px"
					iconPosition="end"
					buttonMarginLeft={buttonMarginLeft}
				>
					{btnText}
				</CustomButton>
			</Box> 
		</Stack>
	);
};

export default FilterSearch;
