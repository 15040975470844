import { borderRadius, fontSize, margin, maxWidth, width } from "@mui/system";

// Define your style objects
export const customerTabStyles = {
    infoTitle: {
        color: "#0043CE",
        fontSize: "14px",
        fontWeight: "500",
    },
    infoDesc: {
        color: "#161616",
        fontSize: "12px",
        fontWeight: "400",
        textAlign: "center",
    },
    addSiteButContainer: { marginTop: "16px" },
    addSiteButtonIcon: { marginRight: "18px" },
    customerList: {
        padding: "10px 16px 10px 24px",
        borderBottom: "1px solid #E0E0E0",
        margin: 0,
        h6: {
            display: "flex",
            padding: "10px 16px 10px 24px",
            alignItems: "center",
            gap: "8px",
            flex: "1 0 0",
            width: "242px",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            color: "#525252",
            borderBottom: "1px solid #E0E0E0",
        },
        activeClass: {
            backgroundColor: "#E0E0E0",
            padding: "10px 16px 10px 24px",
            borderRight: "1px solid  #E0E0E0",
            borderBottom: "1px solid #E0E0E0",
            margin: 0,
        },
        checkBoxStyle: {
            display: "none",
        },
    },
    areaList: {
        padding: "9px 16px",
        borderBottom: "1px solid #E0E0E0",
        h6: {
            display: "flex",
            padding: "10px 16px 10px 24px",
            alignItems: "center",
            gap: "8px",
            flex: "1 0 0",
            width: "242px",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            color: "#525252",
            borderBottom: "1px solid #E0E0E0",
        },
        margin: 0,
        checkBoxStyle: {
            margin: 0,
            padding: "0 8px 0 0",
        },
        areaListIcon: {
            marginLeft: "auto", // Push the icon to the end
        },
    },
    siteList: {
        padding: "9px 16px",
        borderBottom: "1px solid #E0E0E0",
        margin: 0,
        h6: {
            display: "flex",
            padding: "10px 16px 10px 24px",
            alignItems: "center",
            gap: "8px",
            flex: "1 0 0",
            width: "240px",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            color: "#525252",
            borderBottom: "1px solid #E0E0E0",
        },
        checkBoxStyle: {
            margin: 0,
            padding: "0 8px 0 0",
        },
    },
    areaBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    customerBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    myNoStack: {
        direction: "column",
        justifyContent: "center",
        alignItems: "center",
        height: 248,
        width: 284,
        minWidth: 284,
        display: "flex",
        fontSize: "14px",
        fontWeight: "500",
    },
    searchContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginLeft: "auto",
        alignItems: "center",
        margin: "4px 16px 4px auto",
    },
    searchBox: {
        borderRadius: "4px",
        background: "#F4F4F4",
        width: "343px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        height: "30px",
    },
    searchSelect: {
        paddingLeft: "16px",
        marginRight: "0px",
        border: "none",
        "& .MuiOutlinedInput-notchedOutline": { border: 0 },
        width: "206px",
        "& .MuiSelect-icon": {
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
        },
        "& .MuiSelect-select": {
            backgroundColor: "#F4F4F4",
            padding: 0,
            fontSize: "14px",
        },
        "& .MuiInput-input:focus": {
            backgroundColor: "#F4F4F4",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#0F62FE",
            borderWidth: "2px",
        },
    },
    searchTextfield: {
        paddingRight: "0px",
        borderRadius: "0px",
        height: "32px",
        "& .MuiFormControl-root": {
            width: "195px",
        },
        "& .MuiOutlinedInput-input": {
            padding: "0px 14px 0px 14px",
        },
        "& .MuiOutlinedInput-root": {
            height: "32px",
            fontSize: "14px",
            "& fieldset": {
                border: "none",
            },
        },
        "& input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 30px #F4F4F4 inset",
            WebkitTextFillColor: "inherit",
        },
    },
    noCustBox: {
        direction: "column",
        justifyContent: "center",
        alignItems: "center",
        height: 200,
        width: 250,
        minWidth: 564,
        display: "flex",
    },
    custDividerStyle: {
        height: "309px",
        marginTop: "28px !important",
    },
    recentOuterBox: {
        height: "308px", overflowY: "auto", overflowX: "clip"
    },
    listOuterContainer: {
        display: "flex",
        alignItems: "center",
        maxWidth: "100%",


    },
    listParent: {
        paddingBottom: '0px', paddingTop: '0px',

    },
    recentTypography: {
        whiteSpace: "pre-wrap",
        background: "white",
        marginTop: "-2.9px",
        paddingTop: "6px",
        paddingLeft: "6px",
        paddingRight: "6px",
        paddingBottom: "6px",
        // marginLeft: "-3px",
        borderRadius: "4px",
        marginBottom: "-2.9px",
        marginLeft: "-5.9px",
        color: "black",
        width: "100%"
    },
    recentHeaderText: {
        marginBottom: "8px",
        fontSize: "16px",
        fontWeight: "500",
        width: "fit-content"
    },

    listItemParent: {
        paddingTop: "0px",
        height: "54px"
    },
    recentListItem: {
        '&:hover': {
            backgroundColor: '#6F6F6F1F',
        },
        paddingRight: '16px',
        display: 'flex',
        paddingTop: "0px",
        paddingBottom: "0px",

    },
    recentArrowIcon: {
        verticalAlign: 'middle',
        width: '16px',
        height: '16px',
        margin: "0px 2px 0px 2px",
    },
    recentArrowIconTooltip: {
        verticalAlign: 'middle',
        width: '16px',
        height: '16px',
        margin: '0px 2px',
        '& .chevron-icon': {
            fill: 'white',
        },
    },
    listContainer: {
        display: 'flex',
        marginRight: '16px',
        justifyContent: "center",
        marginTop: "-30px"
    },

    listItemTypography: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        display: 'inline-block',
        cursor: 'pointer',
        fontSize: '12px',
        position: 'relative',
        color: "#525252",

    },
    noRecentSearch: {
        direction: "column",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        height: "100%"
    },
    landingPageSearchContainer: {
        height: "44px",
        background: "white",
        padding: "11px 16px 11px 16px",
        borderRadius: "8px",
        border: "1px solid #C6C6C6",
        display: "flex !important",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "16px"
    },
    landingSearchBar: {
        borderRadius: "4px",
        background: "#f4f4f4",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "49%",
        height: "40px",
        border: '1px solid #E0E0E0',
        "& .MuiInputBase-root": {
            paddingLeft: "0px"
        }

    },
    landingPageSearchInput: {
        paddingRight: "0px",
        borderRadius: "0px",
        height: "40px",
        "& .MuiOutlinedInput-input": {
            padding: "0px 14px 0px 14px",
        },
        "& .MuiOutlinedInput-root": {
            height: "40px",
            fontSize: "14px",
            "& fieldset": {
                border: "none",
            },
        },
        "& input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 30px #F4F4F4 inset",
            WebkitTextFillColor: "inherit",
        },
    },
    landingPageSelect: {
        paddingLeft: "16px",
        marginRight: "0px",
        border: "none",
        "& .MuiOutlinedInput-notchedOutline": { border: 0 },
        width: "130px",
        "& .MuiSelect-icon": {
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
        },
        "& .MuiSelect-select": {
            backgroundColor: "#F4F4F4",
            padding: 0,
            fontSize: "14px",
        },
        "& .MuiInput-input:focus": {
            backgroundColor: "#F4F4F4",
        },
    },
    areaLabel: {
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "24px",
    }
};
