import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { NWC_LOGIN_URL, NWC_RESET_PASSWORD } from "../common/constants/Constants";
import { useAuth } from "../common/utils/AuthContext";
import { usePermissions } from "../common/utils/usePermissions";

const PrivateRoute = ({ children, resource, action }) => {
  const { user } = useAuth();
  const location = useLocation();
  const { hasPermission } = usePermissions(user?.role);

  // Check for accessToken in localStorage
  const accessToken = localStorage.getItem('accessToken');

  // First check if user is authenticated and has valid token
  if ((!user || !accessToken) && location?.pathname !== NWC_RESET_PASSWORD) {
    // Save the attempted URL for redirecting after login
    return <Navigate to={NWC_LOGIN_URL} state={{ from: location }} replace />;
  }

  // Check if password change is required and user is not already on reset password page
  if (user?.passwordChangeRequired && location.pathname !== NWC_RESET_PASSWORD) {
    return <Navigate to={NWC_RESET_PASSWORD} replace />;
  }

  // If resource and action are provided, check permissions
  if (resource && action) {
    if (!hasPermission(resource, action)) {
      return <Navigate to="/unauthorized" replace />;
    }
  }

  // If authenticated and has permission (or no permission check required), render children
  return children;
};

export default PrivateRoute;