import React, { useEffect, useState } from "react";
import { Tooltip } from "@mui/material"; // Add this import at the top
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    Divider,
    Stack
} from "@mui/material";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import spinner from "../../../../../../assets/animation/spinner.json";
import { ReactComponent as ChevronRight } from "../../../../../../assets/images/RightArrow.svg";
import useFetch from "../../../../../../common/hook/useFetch";
import { getRecentSearch } from "../../../../../../services/apiConfig";
import { ReactComponent as FavBlueStar } from "../../../../../../assets/images/FavBlueStar.svg";
import { customerTabStyles } from "../allCustomerTab/AllCustomerTabStyle";
import { getSingleStateName, getStateLabelForListing } from "../../../../../../common/utils/CommonUtils";
import {
    setSelectedCategory,
    setSelectedAreas,
    setSelectedSites,
    setRecentSearchId,
    setUpdateBeadcrumbs,
} from "../../../../../../redux/slices/customerFilterSlice";
import { prefectures } from "../../../../../setting/CustomerData";
import { NWC_ALARM_URL } from "../../../../../../common/constants/Constants";
import { setIsDefaultFavorite } from "../../../../../../redux/slices/customerFilterSlice";

const RecentsTab = ({ handleClose, searchType = "customer", searchQuery = "" }) => {
    const {
        data: recentData,
        error: recentError,
        loading: recentLoading,
        fetchData: getRecentData,
    } = useFetch();
    const userId = localStorage.getItem("userId");
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const {
        landingPage: { recentTab }
    } = t("mainContainer");
    const [recentDatum, setRecentDatum] = useState([])

    const [hasSearched, setHasSearched] = useState(false);

    useEffect(() => {
        if (searchQuery) {
            setHasSearched(true);
        }
    }, [searchQuery]);

    useEffect(() => {
        if (userId) {
            getRecentData(getRecentSearch(userId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (Array.isArray(recentData)) {
            const validRecents = recentData?.filter(item => item?.siteList?.length > 0);
            setRecentDatum(validRecents);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recentData]);

    const noRecentFunction = () => {
        return (
            <Stack
                sx={customerTabStyles.noRecentSearch}
            >
                <Typography sx={customerTabStyles.infoTitle}>
                    {recentTab?.noSearchTitle}
                </Typography>
                <Typography sx={customerTabStyles.infoDesc}>
                    {recentTab?.noSearchSubTitle}
                </Typography>
            </Stack>
        );
    };

    const noRecentSearchFunction = () => {
        return (<> 
                {/* Only show "no results" message if user has actually searched */}
                {!recentLoading && !recentError && recentData?.length > 0 && 
                 hasSearched && filteredCust?.length === 0 && ( <Stack sx={customerTabStyles.noRecentSearch}>
                    <Typography sx={customerTabStyles.infoTitle}>
                        {recentTab?.recentNoSearch}
                    </Typography> </Stack>
                )}
           </>
        );
    };

    const handleItemSelect = (customer) => {

        if(customer?.defaultFavourite)
            { 
                dispatch(setIsDefaultFavorite(true));
            }else{
                dispatch(setIsDefaultFavorite(false));
            }
        dispatch(setUpdateBeadcrumbs(true));
        const alarmType = customer?.favouriteType?.length === 1 && customer?.favouriteType?.includes("alarm");
        const areas = [
            ...new Set(customer?.siteList?.map((site) => getStateLabelForListing(site?.state))),
        ];
        const transformApiResponse =
            customer?.siteList?.map((item) => ({
                id: item?.siteId,
                state: getStateLabelForListing(item?.state),
                name: item?.siteName
            }));
        dispatch(setSelectedSites(transformApiResponse))
        dispatch(setSelectedAreas(areas))
        const isFav = customer?.isFavourite && customer?.favouriteName;
        if (isFav) {
            dispatch(setSelectedCategory({ id: customer?.customerId, name: customer?.customerName, displayName: customer?.customerName, favouriteName: customer?.favouriteName }));
        }
        else {
            dispatch(setSelectedCategory({ id: customer?.customerId, name: customer?.customerName, displayName: customer?.customerName }));
        }
        dispatch(setRecentSearchId(customer?.id))
        handleClose();
        if (alarmType) {
            navigate(NWC_ALARM_URL);
        }
    }

    const filteredCust = recentDatum?.filter((item) => {
        if (searchType === "customer") {
            return item?.customerName?.toLowerCase()?.includes(searchQuery?.toLowerCase());
        } else if (searchType === "area") {
            return item?.siteList?.some(site => {
                const stateName = prefectures?.find(pref => pref?.value === site?.state)?.label?.toLowerCase();
                return stateName?.includes(searchQuery?.toLowerCase());
            });
        } else if (searchType === "site") {
            return item?.siteList?.some(site =>
                site?.siteName?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            );
        }
        else if (searchType === "favorite" && searchQuery != "") {
            return item?.isFavourite && item?.favouriteName?.toLowerCase()?.includes(searchQuery?.toLowerCase());
        }
        else return true;
    });
    if(recentLoading && !recentData && !recentError){
        return <div className="spinner-wrapper blue-spinner">
        <Lottie className="spinner" animationData={spinner} autoplay loop />
    </div>
    }
    return (
        <Box sx={customerTabStyles.recentOuterBox}>
           
               
                  {  (!hasSearched || !!filteredCust?.length) && (
                        filteredCust?.map((customer, index) => {
                            const areaCodes = [
                                ...new Set(customer?.siteList?.map((site) => site?.state)),
                            ];
                            const areas = areaCodes
                                ?.map((code) => getSingleStateName(code))
                                ?.join(", ");
                            const sites = customer?.siteList
                                ?.map((site) => site?.siteName)
                                .join(", ");
                            const isSiteDeleted = customer?.siteList?.some(site => site?.isSiteDeleted);
                            const headerText =
                                customer?.isFavourite && customer?.favouriteName ? customer?.favouriteName : customer?.customerName;
                            const truncateText = (text, maxLength) => {
                                if (text?.length > maxLength) {
                                    return `${text.substring(0, maxLength)}...`;
                                }
                                return text;
                                };
                                return (
                                <>
                                    {index === 0
                                        && <Divider style={{ marginTop: "16px", marginBottom: "0px" }} />}{" "}
                                    <List sx={customerTabStyles?.listParent} >
                                        <div key={index} >
                                            <ListItem
                                                sx={{
                                                    ...customerTabStyles.recentListItem,
                                                    opacity: isSiteDeleted ? 0.5 : 1,
                                                    pointerEvents: isSiteDeleted ? 'none' : 'auto',
                                                }}
                                                onClick={!isSiteDeleted ? () => handleItemSelect(customer) : undefined}
                                            >

                                                <div style={{ ...customerTabStyles.listOuterContainer }}>
                                                    <div
                                                        style={{
                                                            visibility: `${!(customer?.isFavourite) && "hidden"}`,
                                                            ...customerTabStyles.listContainer

                                                        }}
                                                    >
                                                        <FavBlueStar fontSize="inherit" />
                                                    </div>
                                                    <ListItemText
                                                        primary={
                                                            <Typography
                                                                variant="body1"
                                                                sx={customerTabStyles.listItemTypography}
                                                            >
                                                                 <Tooltip title={headerText} arrow placement="bottom-end"><div
                                                                    style={customerTabStyles?.recentHeaderText}
                                                                className="resent-titleElips"
                                                                >
                                                               {truncateText(headerText,128)}
                                                                  
                                                                </div></Tooltip>
                                                                <Tooltip title={customer?.customerName} arrow placement="bottom-end"> {truncateText(customer?.customerName,25)}</Tooltip>
                                                                {areas && (
                                                                    <ChevronRight

                                                                        style={customerTabStyles.recentArrowIcon}
                                                                    />
                                                                )}
                                                                {areas}
                                                                {sites && (
                                                                    <ChevronRight

                                                                        style={customerTabStyles.recentArrowIcon}
                                                                    />
                                                                )}
                                                                  <Tooltip title={sites} arrow placement="bottom-end"><div 
                                                                className="titleElips display-inline"
                                                                >
                                                               {truncateText(sites,128)}
                                                                  
                                                                </div></Tooltip>
                                                                {/* <Tooltip title={sites} arrow placement="bottom-end">{sites}</Tooltip> */}
                                                            </Typography>
                                                        }
                                                    />
                                                </div>
                                            </ListItem>
                                            {index <
                                                filteredCust
                                                    ?.length - 1 && <Divider component="li" />}{" "}
                                        </div>
                                    </List >
                                </>

                            );
                        }))}
                  {(!recentLoading && !recentError && recentData && recentData?.length === 0) && noRecentFunction()}
                  {noRecentSearchFunction()} 


        </Box >
    );
};

export default RecentsTab;
