import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const ITEM_HEIGHT = 48;
export default function TableActions(props) {
  const { options, handleActionSelection, actionIcon, setSelectedAction = () => {} } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedAction, setSelectedActionState] = React.useState(""); // State to track the selected action
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option, index) => {
    setSelectedActionState(option);
    handleActionSelection(option, index);
    setSelectedAction(option);
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {actionIcon ? actionIcon : <MoreVertIcon />}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              ml: "-2rem", 
              minWidth:"160px"
            },
          },
        }}
        open={open}
        onClose={handleClose} 
      >
        {options?.map((option, index) => (
          <MenuItem
            key={option}
            selected={option === selectedAction} // Highlight the selected item
            onClick={() => handleMenuItemClick(option,index)}
            disabled={option === "Disabled"||option === "無効"}
            className="paginationSelect"
        
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
