/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Typography, Alert, IconButton } from "@mui/material";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Close from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import { ReactComponent as RedErrorIcon } from "../../assets/images/RedErrorIcon.svg";
import "./Login.scss";
import { forgotPasswordApi } from "../../redux/slices/loginInfoSlice";
import { forgotPasswordEndPoint } from "../../services/apiConfig";
import CustomButton from "../../common/buttons/CustomButton";
function ForgotPassword({
  forgotPassword,
  theme,
  // enteredEmail,
  // setForgotPasswordClicked,
  setShowResetPassword,
  defaultLanguage,
}) {
  const {
    sendLinkButton,
    headerOne,
    headerThree,
    headerFour,
    forgotPasswordNewLabel,
    apiError
  } = forgotPassword;
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [labelError, setLabelError] = useState(false);
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const { disableForgotPassword } = useSelector((state) => state.loginInfo);
  const enteredEmail = localStorage.getItem("userEnteredEmail");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const apiPayload = {
      url: forgotPasswordEndPoint,
      payload: { email: enteredEmail, language: defaultLanguage },
    };
    dispatch(forgotPasswordApi(apiPayload))
      .then((res) => {
        if (res?.payload?.status == 204) {
          setEmailSent(true);
          dispatch(updateForgotPasswordButton(false));
        } else {
          setLabelError(true)
          setOpen(true);
          console.error("Forgot Password API call failed", res);
        }
      })
      .catch((error) => {
        setLabelError(true)
        setOpen(true);
        console.error("Forgot Password API call failed with error", error);
      });
  };
  // const handleShowResetPasswordScreen = () => {
  //   setShowResetPassword(true);
  //   navigate(NWC_RESET_PASSWORD);
  // };
  useEffect(() => {
    if (enteredEmail) {
      setEmail(enteredEmail);
    }
  }, [enteredEmail]);
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={2}
      marginTop={emailSent ? "42%" : "35%"}
      marginBottom={"8%"}
      width={"330px"}
      className={defaultLanguage === "ja" ? "boxChildFont" : ""}
    // fontSize={defaultLanguage === "ja" ? "12px" : ""}
    >
      <>
        <Typography variant="h5" fontWeight={"500"}>
          {emailSent ? headerThree : headerOne}
        </Typography>
        <Typography theme={theme} variant="pFontSize">
          {emailSent ? headerFour : forgotPasswordNewLabel}
        </Typography>
        {!emailSent ? (
          <>
            {" "}
            <Typography
              theme={theme}
              color="primary"
              style={{
                marginTop: "-6px",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0.25px",
              }}
            >
              {enteredEmail}
            </Typography>
            {labelError && (
              <Collapse in={open}>
                <Alert
                  severity="error"
                  icon={<RedErrorIcon />}
                  sx={{
                    border: {
                      borderLeft: "3px solid #DA1E28",
                      borderTop: "1px solid #DA1E284D",
                      borderRight: "1px solid #DA1E284D",
                      borderBottom: "1px solid #DA1E284D",
                    },
                    mb: 2,
                    fontSize: "12px",
                  }}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <Close fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {apiError}
                </Alert>
              </Collapse>
            )}
            <CustomButton
              type="submit"
              variant="contained"
              backgroundColor="primary"
              onClick={handleFormSubmit}
              background="#0F62FE"
              fontSize="16px"
              fontWeight="400"
              height="48px"
              padding="12px 16px"
              disabled={disableForgotPassword}
            >
              {sendLinkButton}
            </CustomButton>{" "}
          </>
        ) : (
          <Typography color="primary" fontWeight="500">
            {enteredEmail}
          </Typography>
        )}
      </>
    </Box>
  );
}

export default ForgotPassword;