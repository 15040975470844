import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const LargeReusableModal = ({
  isOpen,
  onClose,
  header,
  footer,
  children,
  buttonPosition = "center",
  maxWidth = "md",
  footerMarginRight,
  footerBgColor,
  footerMarginLeft,
  dialogHeight = "auto", // Default height

}) => {
  const buttonAlignment = {
    right: "flex-end",
    center: "center",
    left: "flex-start",
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={false} // Allow custom width
      PaperProps={{
        style: {
          height: dialogHeight,
          maxHeight: "100%",
          overflowY: "auto",
          maxWidth: maxWidth !== "md" ? maxWidth : "370px", // Optional: for responsive design
          margin: "auto", // Center the dialog
          borderRadius: "8px"
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        style={{ position: "absolute", top: 10, right: 10 }}
      >
        <CloseIcon />
      </IconButton>
      {header && <DialogTitle
        sx={{
          "&.MuiDialogTitle-root": {
            padding: "14px 24px"
          }
        }}
      >{header}</DialogTitle>}
      <Divider />
      <DialogContent style={{ padding: "24px", overflowY: "auto" }}>
        {children}
      </DialogContent>
      {
        footer && (
          <DialogActions
            style={{
              backgroundColor: footerBgColor,
              justifyContent: buttonAlignment[buttonPosition],
              marginLeft: footerMarginLeft,
              marginRight: footerMarginRight,
              padding: "16px 24px",
              lineHeight: "28px"
            }}
          >
            {footer?.map((action, index) => (
              <Button
                style={action?.style ?? {}}
                key={index}
                onClick={action?.onClick}
                color={action?.color}
                variant={action?.variant}
                disabled={action?.disabled}
              >
                {action?.label}
              </Button>
            ))}
          </DialogActions>
        )
      }
    </Dialog >
  );
};

export default LargeReusableModal;
