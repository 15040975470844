import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, IconButton, Box, Alert } from '@mui/material';
import './AcknolowledgeModal.scss'
import { ReactComponent as CloseIcon } from '../../../assets/images/CloseIcon.svg';
import { ReactComponent as RedErrorIcon } from "../../../assets/images/RedErrorIcon.svg";
import { ReactComponent as TickIcon } from "../../../assets/images/tickIcon.svg";
import { prefectures } from '../../setting/CustomerData';

export default function AcknowledgeModal({ selectedAlarm, onClose, onAcknowledge, readOnly, isAcknowledging, acknowledgeStatus ,acknowledgedAlarmId}) {
  const [isAcknowledged, setIsAcknowledged] = useState(selectedAlarm?.acknowledgmentStatus === 'Acknowledged');
  const { t } = useTranslation();
  const { alarm  } = t("mainContainer");
  const handleAcknowledge = async () => {
    onAcknowledge(selectedAlarm.id);
  };

  useEffect(() => {
    if (acknowledgeStatus) {
      setIsAcknowledged(true);
    }
  }, [acknowledgeStatus]);

  if (!selectedAlarm) return null;

  return (
    <Dialog open={!!selectedAlarm} onClose={onClose} className='acknolowledgeModal'>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {alarm.Alarmdetails}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {acknowledgeStatus && (
          <Alert 
            icon={acknowledgeStatus.type === 'success' ? <TickIcon fontSize="inherit" /> : <RedErrorIcon fontSize="inherit" />} 
            severity={acknowledgeStatus.type} 
            sx={{ mb: 2, alignSelf: "stretch" }}
          >
            <div className="alertTitle">{acknowledgeStatus?.title}</div>
           <div className="alertInfo"> {acknowledgeStatus?.message}</div>
          </Alert>
        )}
        <Typography><strong>{alarm?.alarmSummaryHeader["Status"]}</strong>{alarm.status[selectedAlarm.status]}</Typography>
        <Typography><strong>{alarm?.alarmSummaryHeader["Priority"]}</strong>{alarm.priority[selectedAlarm.priority]} </Typography> 
        <Typography><strong>{alarm?.alarmSummaryHeader["Timestamp"]}</strong> {selectedAlarm.timestamp}</Typography>
        <Typography><strong>{alarm?.alarmSummaryHeader["Description"]}</strong> {selectedAlarm.description}</Typography>
        <Typography><strong>{alarm?.alarmSummaryHeader["Name"]}</strong> {selectedAlarm.name}</Typography>
        <Typography><strong>{alarm?.alarmSummaryHeader["Value"]}</strong> {selectedAlarm.value}</Typography>
        <Typography><strong>{alarm?.alarmSummaryHeader["Limit"]}</strong> {selectedAlarm.limit}</Typography>
        <Typography><strong>{alarm?.alarmSummaryHeader["Type"]}</strong> { alarm.type[selectedAlarm.type]}</Typography> 
        <Typography><strong>{alarm?.alarmSummaryHeader["Prefecture"]}</strong> {prefectures?.find(p => p.value === selectedAlarm.state)?.label} </Typography> 
        <Typography><strong>{alarm?.alarmSummaryHeader["Site Name"]}</strong> {selectedAlarm.siteName}</Typography> 
        <Typography><strong>{alarm?.alarmSummaryHeader["AcknowledgmentStatus"]}</strong> {isAcknowledged&& acknowledgedAlarmId ? alarm.ackStatus['Acknowledged']  :  alarm.ackStatus[selectedAlarm.acknowledgmentStatus]}</Typography>
          
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'flex-end' }}>
        <Button onClick={onClose} variant="outlined">{alarm.Cancel}</Button>
        {!readOnly && !(isAcknowledged && acknowledgedAlarmId) && (
          <Button onClick={handleAcknowledge} disabled={isAcknowledging} variant="contained" color="primary">
            {isAcknowledging ? `${alarm.Acknowledging}...` : `${alarm.Acknowledge}`}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
