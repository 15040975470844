import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import { Stack, Box, Typography, Button, Modal, Grid } from "@mui/material";
import { Divider } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material"; // Add this import at the top
import { useNavigate ,useLocation} from "react-router-dom";
import { ReactComponent as UserFilledIcon } from "../../assets/images/User-filled.svg";
import { ReactComponent as LargeUserIcon } from "../../assets/images/userIcon.svg";
import { ReactComponent as NwcLogo } from "../../assets/images/NWC-black-logo.svg";
import { ReactComponent as BrandLogo } from "../../assets/images/brandLogo.svg";
import { ReactComponent as LockIcon } from "../../assets/images/lockIcon.svg";
import { ReactComponent as SignoutIcon } from "../../assets/images/signout.svg";
import LanguageSelector from "../../common/language-selector/LanguageSelector";
import { updateEdgeDeviceToken } from "../../redux/slices/loginInfoSlice";
import { NWC_LOGIN_URL } from "../../common/constants/Constants";
import { logoutApi, updateLoader } from "../../redux/slices/loginInfoSlice";
import ChangePasswordModal from "../ChangePasswordModal";
import { logoutEndPoint, edgeDeviceUrl } from "../../services/apiConfig";
import "../langing-page/LandingPages.scss";
import { setRecentSearchId } from "../../redux/slices/customerFilterSlice";
import { resetCustomerState } from "../../redux/slices/customerSlice";
import { resetCustomerFilterState } from "../../redux/slices/customerFilterSlice";

function Header({ user }) {
  const [openUserIconModal, setOpenUserIconModal] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { headerContent } = t("mainContainer");
  const location = useLocation();
  const { brandName, role, changePwd, signOut } = headerContent;
  const edgeDeviceLogoutUrl = `${edgeDeviceUrl}auth/logout`;

  const handleOpen = () => setOpenUserIconModal(true);
  const handleClose = () => setOpenUserIconModal(false);
  const handleClick = (key) => {
    if (key == "passwordChange") {
      setOpenUserIconModal(false);
      setOpenChangePasswordModal(true);
    } else {
      const token = localStorage.getItem("refreshToken");
      const payload = { refreshToken: token };
      const url = logoutEndPoint;
      const apiPayload = { url, payload };
      // Parse the data from window.name, or initialize an empty object if not set
      let tabData = JSON.parse(window.name || '{}');
      tabData.emsTabUrl = edgeDeviceLogoutUrl; // Set the logout URL
      window.name = JSON.stringify(tabData);
      // Ensure emsTabReferences exists and is an array before proceeding
      if (Array.isArray(window?.emsTabReferences) && window?.emsTabReferences?.length > 0) {
        // Loop through and close each open tab
        window?.emsTabReferences?.forEach(({ tab }) => {
          if (tab && !tab?.closed) {
            tab.location.href = edgeDeviceLogoutUrl;
            // Delay closing the tab slightly to ensure the logout URL loads
            setTimeout(() => {
              tab.close();
            }, 7000);
          }
        });

        // Clear the array after closing all tabs
        window.emsTabReferences = [];
      } else {
        console.log("No EMS tabs are currently open.");
      }
      dispatch(logoutApi(apiPayload)).then((res) => { 
        if (res?.payload?.status == "204") {
          dispatch(updateEdgeDeviceToken(""))
          dispatch(resetCustomerState());
          dispatch(resetCustomerFilterState());
          const lang = localStorage.getItem("i18nextLng");
          dispatch(updateLoader("loading"));
          localStorage.clear();
          navigate(NWC_LOGIN_URL);
          localStorage.setItem("i18nextLng", lang);
          dispatch(setRecentSearchId(""));
        }
      });
    }

  };

  const handleCloseModal = () => {
    setOpenChangePasswordModal(false);
  };
  const style = {
    position: "absolute",
    top: "10%",
    right: "0%",
    width: 270,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "7px",
    height: 'auto',
    padding: '0px'
  };
  return (
    <Grid
      item
      xs={12}
      sx={{
        // position: "sticky !important",
        display: "flex",
        borderBottom: "0.1px solid #C6C6C6",
        borderBottomWidth: "thin",
        justifyContent: "space-between",
        padding: "0 16px",
      }}
      className="headerHeight"
    >
      <Grid
        item
        md={6}
        xs={12}
        display={"flex"}
        justifyContent={"space-between"}
        marginLeft={1}
      >
        <Typography
          marginTop={"10px"}
          color={"black"}
          fontSize={"20px"}
          fontWeight="500"
        >
          <NwcLogo />
        </Typography>
        <Divider
          orientation="vertical"
          flexItem
          style={{ marginTop: "10px", height: "28px ", marginLeft: "16px" }}
        />
        <Typography
          marginTop={"10px"}
          color={"black"}
          fontSize={"20px"}
          fontWeight="500"
        >
          <BrandLogo />
        </Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-around !important"}
          gap={"12px"}
        >
          <Typography
            className="languageSelector"
            textAlign="center"
            component="span"
          >
            <LanguageSelector backgroundShowBtn={true} />
          </Typography>
          <IconButton
            onClick={handleOpen}
            sx={{ height: "40px", width: "40px", marginTop: "2%" }}
          >
            <UserFilledIcon />
          </IconButton>
        </Stack>
        {/* <div> */}
        <Modal
          BackdropProps={{ invisible: openChangePasswordModal }}
          open={openUserIconModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" component="h2" display="flex" style={{ padding: '16px 24px' }}>
              <LargeUserIcon />
              <div
                style={{ display: "flex", flexDirection: "column" }}
                className="userFontSize"
              >
                 <Tooltip title={user?.name} arrow placement="bottom-end">
                 <span className="displayName">{user?.name}</span>
                      </Tooltip>
                <span>{user?.role}</span>
              </div>
            </Typography>
            <Typography
              component="div"
              id="modal-modal-description"
              style={{ marginTop: '0px' }}
            >
              <div
                className="modal-desc"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  className="userBlock"
                >
                  <LockIcon className="buttonSizing" />
                  <Button
                    color="inherit"
                    onClick={() => handleClick("passwordChange")}
                    className="passwordClick"
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
                  >
                    {changePwd}
                  </Button>
                </div>
                <div
                  className="userBlock"

                >
                  <SignoutIcon className="buttonSizing" />
                  <Button
                    color="inherit"
                    onClick={() => handleClick("signOut")}
                    className="signOutClick"
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
                  >
                    {signOut}
                  </Button>
                </div>
              </div>
            </Typography>
          </Box>
        </Modal>
        <ChangePasswordModal
          open={openChangePasswordModal}
          handleClose={handleCloseModal}
          user={user}
        />
      </Grid>
    </Grid>
  );
}
export default Header;