import axios from "axios";
import { refreshTokensEndPoint } from "../services/apiConfig";

// Create an axios instance
export const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_NWC_BASE_URL}:${process.env.REACT_APP_NWC_PORT}`,
});

// Function to refresh the token
export const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  if (refreshToken) {
    try {
      const response = await axios.post(refreshTokensEndPoint, {
        refreshToken,
      });
      localStorage.setItem("accessToken", response?.data?.access?.token);
      localStorage.setItem("refreshToken", response.data?.refresh?.token);
      localStorage.setItem("accessExpire", response?.data?.access?.expires);
      localStorage.setItem("refreshExpire", response?.data?.refresh?.expires);
      scheduleTokenRefresh();
    } catch (error) {
      console.error("Error refreshing token:", error);
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessExpire");
      localStorage.removeItem("refreshExpire");
    }
  }
};

// Add this function to check if token is expired
const isTokenExpired = (expiryTime) => {
  if (!expiryTime) return true;
  const expirationTime = new Date(expiryTime).getTime();
  const currentTime = Date.now();
  return currentTime >= expirationTime;
};

// Modify the scheduleTokenRefresh function
const scheduleTokenRefresh = () => {
  const accessTokenExpiry = localStorage.getItem("accessExpire");
  if (accessTokenExpiry) {
    if (isTokenExpired(accessTokenExpiry)) {
      refreshToken(); // Refresh immediately if token is expired
    } else {
      const expirationTime = new Date(accessTokenExpiry).getTime();
      const currentTime = Date.now();
      const timeUntilExpiration = expirationTime - currentTime - 30000;
      if (timeUntilExpiration > 0) {
        setTimeout(refreshToken, timeUntilExpiration);
      } else {
        refreshToken();
      }
    }
  }
};

// Add immediate token check when the module loads
const initializeTokenCheck = () => {
  const accessTokenExpiry = localStorage.getItem("accessExpire");
  const refreshTokenExpiry = localStorage.getItem("refreshExpire");
  
  // Clear tokens if refresh token is expired
  if (isTokenExpired(refreshTokenExpiry)) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("accessExpire");
    localStorage.removeItem("refreshExpire");
    return;
  }
  
  // Check and refresh access token if needed
  if (isTokenExpired(accessTokenExpiry)) {
    refreshToken();
  } else {
    scheduleTokenRefresh();
  }
};

// Call initialization immediately
initializeTokenCheck();

axiosInstance.interceptors.request.use(async (req) => {
  const accessTokenExpiry = localStorage.getItem("accessExpire");
  if (accessTokenExpiry) {
    const expirationTime = new Date(accessTokenExpiry).getTime();
    const currentTime = Date.now();
    const timeUntilExpiration = expirationTime - currentTime;
    // Refresh token if it's about to expire within the next 30 seconds
    if (timeUntilExpiration <= 800000) {
      await refreshToken();
      // Recalculate token expiration and reschedule
      scheduleTokenRefresh();
    }
  }
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    req.headers.Authorization = `Bearer ${accessToken}`;
  }
  return req;
});

export default axiosInstance;
