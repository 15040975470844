import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Tooltip } from "@mui/material"; // Add this import at the top
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { breadcrumbStyles } from './BreadcrumbStyles';

function handleClick(event) {
  event.preventDefault(); 
}

const truncateText = (text, maxLength) => {
  if (text?.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
};

const  Breadcrumb=({primaryLink,secodaryLink,activeLink})=> {
  
  const breadcrumbs = [
    <Link sx={breadcrumbStyles.label} underline="hover" key="1" color="inherit" href="/" onClick={handleClick}>
    
    <Tooltip title={primaryLink} arrow placement="bottom-end">{truncateText(primaryLink,15)}</Tooltip>
    </Link>,
    secodaryLink&&<Link
      sx={breadcrumbStyles.areaLink}
      title={secodaryLink}
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={handleClick}
    >
   {secodaryLink}
    </Link>,
    activeLink&&<Tooltip title={activeLink} arrow placement="bottom-end"><Typography sx={breadcrumbStyles.activeLink} key="3" color="text.primary" className="siteElips">
     {activeLink}
    </Typography></Tooltip>,
  ];

  return (
    <Stack spacing={2} className='breadcrums-content'> 
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}

export default Breadcrumb;