import React from "react";
import { Box, LinearProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import useStyles from "./stepper.style";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
const CustomStepper = ({
  isTab,
  steps,
  activeStep,
  fontSize = 16,
  isComplete,
  displaycolor = "#0F62FE",
  height = 16,
  error,
}) => {
  const styles = useStyles();
  
  // Calculate progress based on total number of steps
  const calculateProgress = () => {
    const totalSteps = steps?.length || 1;
    return ((activeStep - 1) / totalSteps) * 100;
  };

  // Conditionally add the transform based on isTab and number of steps
  const getTransformStyle = () => {
    if(isTab){
    if (steps?.length === 2) {
      // For 2 steps
      return `translateX(${activeStep === 1 ? "-60%" : "0%"})!important`;
    }  }
    
    // if (!isTab) return "";
    
    // // Adjust transform values based on number of steps
    // if (steps?.length === 2) {
    //   // For 2 steps
    //   return `translateX(${activeStep === 1 ? "-20%" : "0%"})!important`;
    // } else {
    //   // For 3 steps (original logic)
    //   return `translateX(${
    //     activeStep === 1 ? "-60%" : 
    //     activeStep === 2 ? "-25%" : 
    //     activeStep === 3 ? "0%" : ""
    //   })!important`;
    // }
  };

  return (
    <>
      <Box
        sx={{ width: "100%", marginTop: "10px", marginBottom: "10px" }}
        className={styles}
      >
        <LinearProgress
          variant="determinate"
          value={calculateProgress()}
          sx={{
            height: "2px",
            "& .MuiLinearProgress-bar": {
              backgroundColor: displaycolor,
              transform: getTransformStyle(),
            },
            backgroundColor: "#C6C6C6",
            borderRadius: "12px",
          }}
        />
      </Box>
      <div className={styles?.stepper}>
        {steps?.map((step, index) => (
          <div key={step + index} className={styles.step}>
            <div
              className={`
              ${styles.stepNumber} 
              ${error && activeStep === index + 1 ? styles.error : ""} 
              ${
                activeStep === index + 1 && !error
                  ? styles.activeStepNumber
                  : ""
              }
              ${activeStep > index + 1 || isComplete ? styles.complete : ""}
            `}
            >
              {activeStep > index + 1 || isComplete ? (
                <CheckIcon
                  fontSize="small"
                  sx={{ height: "12px", marginTop: "2px" }}
                  style={{ alignItems: "center" }}
                />
              ) : error && activeStep === index + 1 ? (
                <ErrorOutlineIcon className={styles.errorIcon} />
              ) : (
                " "
              )}
            </div>
            <div className={styles?.stepName}>
              <span>{index + 1}.</span>
              <span style={{ marginLeft: "3px" }}>{step}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CustomStepper;
