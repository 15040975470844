const prefectures = [
  { value: "hk", label: "Hokkaido", country: "jp" },
  { value: "ao", label: "Aomori", country: "jp" },
  { value: "iw", label: "Iwate", country: "jp" },
  { value: "mg", label: "Miyagi", country: "jp" },
  { value: "ak", label: "Akita", country: "jp" },
  { value: "yg", label: "Yamagata", country: "jp" },
  { value: "fs", label: "Fukushima", country: "jp" },
  { value: "ib", label: "Ibaraki", country: "jp" },
  { value: "tc", label: "Tochigi", country: "jp" },
  { value: "gm", label: "Gunma", country: "jp" },
  { value: "st", label: "Saitama", country: "jp" },
  { value: "ch", label: "Chiba", country: "jp" },
  { value: "to", label: "Tokyo", country: "jp" },
  { value: "kn", label: "Kanagawa", country: "jp" },
  { value: "ni", label: "Niigata", country: "jp" },
  { value: "ty", label: "Toyama", country: "jp" },
  { value: "is", label: "Ishikawa", country: "jp" },
  { value: "fk", label: "Fukui", country: "jp" },
  { value: "yn", label: "Yamanashi", country: "jp" },
  { value: "nn", label: "Nagano", country: "jp" },
  { value: "gf", label: "Gifu", country: "jp" },
  { value: "sz", label: "Shizuoka", country: "jp" },
  { value: "ai", label: "Aichi", country: "jp" },
  { value: "me", label: "Mie", country: "jp" },
  { value: "sh", label: "Shiga", country: "jp" },
  { value: "ky", label: "Kyoto", country: "jp" },
  { value: "os", label: "Osaka", country: "jp" },
  { value: "hg", label: "Hyogo", country: "jp" },
  { value: "nr", label: "Nara", country: "jp" },
  { value: "wk", label: "Wakayama", country: "jp" },
  { value: "tt", label: "Tottori", country: "jp" },
  { value: "sm", label: "Shimane", country: "jp" },
  { value: "ok", label: "Okayama", country: "jp" },
  { value: "hs", label: "Hiroshima", country: "jp" },
  { value: "ym", label: "Yamaguchi", country: "jp" },
  { value: "tk", label: "Tokushima", country: "jp" },
  { value: "kg", label: "Kagawa", country: "jp" },
  { value: "eh", label: "Ehime", country: "jp" },
  { value: "kc", label: "Kochi", country: "jp" },
  { value: "fu", label: "Fukuoka", country: "jp" },
  { value: "sg", label: "Saga", country: "jp" },
  { value: "ng", label: "Nagasaki", country: "jp" },
  { value: "km", label: "Kumamoto", country: "jp" },
  { value: "ot", label: "Oita", country: "jp" },
  { value: "mz", label: "Miyazaki", country: "jp" },
  { value: "kh", label: "Kagoshima", country: "jp" },
  { value: "on", label: "Okinawa", country: "jp" },
];

export { prefectures };
