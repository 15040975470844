import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Divider,
  ListItemText,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as LeftArrow } from "../../../assets/images/PaginationArrowLeft.svg";
import { ReactComponent as RightArrow } from "../../../assets/images/PaginationArrowRight.svg";
import { getRequestCustomerApi } from "../../utils/CommonUtils";
import { sortingKeys } from "../../../components/setting/siteDetails/siteDetails.Data";
import { ReactComponent as Check } from "../../../assets/images/CheckMark.svg";
import { styled } from "@mui/system";
import { ReactComponent as ChevronDown } from "../../../assets/images/ChevronDown.svg";

const CommonPagination = ({
  endItemIndex,
  type,
  setEndIndexItem,
  count,
  page,
  onChange,
  rowsPerPage,
  rowsPerPageOptions = [5, 10, 25, 50],
  disabled = false,
  pageNumberEnabled = false,
  searchText = "",
  additionalParams = {},
  arrowWidth,
  arrowBoxMarginRight,
  currentFilter,
  startIndex,
  setStartIndex, 
  sortConfig,
}) => {
  const [totalPages, setTotalPages] = useState(1);
  const [isRowsPerPageOpen, setIsRowsPerPageOpen] = useState(false); 
  const [isPageMenuOpen, setIsPageMenuOpen] = useState(false); 
  const { t } = useTranslation();
  const { settingsPageContent } = t("mainContainer");
  const { paginationContent } = settingsPageContent?.siteManagementContent;

  const { filterSelectionOption } = useSelector(
    (state) => state?.customerInfo
  );

  const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2.5),
    "& .MuiListItemIcon-root": {
      minWidth: theme.spacing(4),
    },
    "&.Mui-selected": {
      marginLeft: "-10px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  }));

  // Ensure count is a valid number
  const validCount = count && !isNaN(count) ? count : 0;

  // Ensure page and rowsPerPage are valid numbers
  const validPage = page && page > 0 ? page : 1;
  const validRowsPerPage = rowsPerPage && rowsPerPage > 0 ? rowsPerPage : 10;

  useEffect(() => {
    // Recalculate totalPages with valid count and rowsPerPage values
    setTotalPages(Math.ceil(validCount / validRowsPerPage));
  }, [validCount, validRowsPerPage]);

  useEffect(() => {
    if (validCount > 0 && validPage && validRowsPerPage) {
      setStartIndex((validPage - 1) * validRowsPerPage + 1);
    } else {
      setStartIndex(0);
    }
  }, [validPage, validRowsPerPage, validCount, setStartIndex]);

  useEffect(() => {
    setEndIndexItem(Math.min(validPage * validRowsPerPage, validCount));
  }, [validPage, validRowsPerPage, validCount, setEndIndexItem]);

  const handleItemsPerPageChange = (event) => {
    if (type !== "customList") { 
      const keyOfAction = "itemsPerPage";
      const sortField = sortConfig.field === 'updatedDate' ? 'createdAt' : sortConfig.field;
      const sortDirection = sortConfig.direction;
      
      let requestParams = getRequestCustomerApi({
        name: searchText,
        limit: parseInt(event.target.value, 10),
        page: 1,
        sortBy: `${sortField}:${sortDirection}`,
        ...additionalParams,
      });
      onChange(1, parseInt(event.target.value, 10), keyOfAction, requestParams);
    } else {
      const keyOfAction = "itemsPerPage";
      let requestParams = getRequestCustomerApi({
        name: searchText,
        limit: parseInt(event.target.value, 10),
        page: 1,
        sortBy: sortingKeys?.[filterSelectionOption],
        ...additionalParams,
      });
      onChange(1, parseInt(event.target.value, 10), keyOfAction, requestParams);
    }
  };

  const handlePreviousPage = () => {
    if (validPage > 1 && !disabled) {
      const keyOfAction = "previous";
      let apiParamsVal = getRequestCustomerApi({
        page: Math.max(validPage - 1, 1),
        limit: validRowsPerPage,
        name: searchText,
        sortBy: sortingKeys?.[filterSelectionOption],
      });
      onChange(Math.max(validPage - 1, 1), validRowsPerPage, keyOfAction, apiParamsVal);
    }
  };

  const handleNextPage = () => {
    if (validPage < totalPages && !disabled) {
      const keyOfAction = "next";
      onChange(
        Math.min(validPage + 1, totalPages),
        validRowsPerPage,
        keyOfAction,
        getRequestCustomerApi({
          page: Math.min(validPage + 1, totalPages),
          limit: validRowsPerPage,
          name: searchText,
          sortBy: sortingKeys?.[filterSelectionOption],
        })
      );
    }
  };

  const handlePageChange = (event) => {
    const keyOfAction = "pageChange";
    let apiParamsVal = getRequestCustomerApi({
      page: event?.target?.value,
      limit: validRowsPerPage,
      name: searchText,
      sortBy: sortingKeys?.[filterSelectionOption],
      ...additionalParams,
    });
    onChange(event?.target?.value, validRowsPerPage, keyOfAction, apiParamsVal);
  };

  const pageOptions = Array.from({ length: totalPages }, (_, i) => i + 1); 
  const customMenuProps = {
    PaperProps: {
      sx: {
        borderRadius: "6px",
        border: "1px solid #A3A5A7",
        background: "#F0F0F0",
        boxShadow: "0px 2px 7px 0px rgba(0, 0, 0, 0.25)",
      },
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        maxHeight: "100%",
        paddingRight: "8px",
        paddingLeft: "8px",
      }}
    >
      <div className="selectParent">
        <Typography variant="span">{paginationContent?.itemPerPage}</Typography>
        <Select
          value={validRowsPerPage}
          onChange={handleItemsPerPageChange}
          onOpen={() => setIsRowsPerPageOpen(true)}
          onClose={() => setIsRowsPerPageOpen(false)}
          IconComponent={ChevronDown}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          {rowsPerPageOptions?.map((option) => (
            <CustomMenuItem
              key={option}
              value={option}
              selected={validRowsPerPage === option}
              className="customMenu"
            >
              {validRowsPerPage === option && isRowsPerPageOpen && (
                <div className="checkIcon">
                  <Check />
                </div>
              )}
              <ListItemText className="optionText" primary={option} />
            </CustomMenuItem>
          ))}
        </Select>
      </div>
      <Divider orientation="vertical" flexItem style={{ marginBottom: "-2px" }} />
      <div style={{ width: pageNumberEnabled && "40%" }}>
        <Typography variant="span" sx={{ marginX: 1 }}>
          {`${startIndex || 0} - ${endItemIndex || 0} ${paginationContent?.of} ${validCount || 0} ${paginationContent?.items}`}
        </Typography>
      </div>

      {pageNumberEnabled && (
        <Divider orientation="vertical" flexItem style={{ marginBottom: "-2px" }} />
      )}

      {pageNumberEnabled && (
        <div className="selectParent">
          <Select
            value={validPage}
            onChange={handlePageChange}
            MenuProps={customMenuProps}
            onOpen={() => setIsPageMenuOpen(true)}
            onClose={() => setIsPageMenuOpen(false)}
            IconComponent={ChevronDown}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            {pageOptions?.map((option) => (
              <CustomMenuItem
                key={option}
                value={option}
                selected={validPage === option}
                className="customMenu"
              >
                {isPageMenuOpen && validPage === option && (
                  <div className="checkIcon">
                    <Check />
                  </div>
                )}
                <ListItemText className="optionText" primary={option} />
              </CustomMenuItem>
            ))}
          </Select>
          <Typography variant="span">
            {paginationContent?.outof} {totalPages} {paginationContent?.pages}
          </Typography>
        </div>
      )}

      <div
        style={{
          display: "flex",
          marginRight: arrowBoxMarginRight,
          marginBottom: "4px",
          width: arrowWidth,
          alignSelf: "end",
          justifyContent: "space-between",
        }}
      >
        <Divider orientation="vertical" flexItem className="paginationDividerMargin" />
        <IconButton
          disabled={validPage === 1 || disabled}
          onClick={handlePreviousPage}
          sx={{
            display: "flex",
            padding: "12px",
            alignItems: "center",
            alignSelf: "stretch",
            opacity: (validPage === 1 || disabled) ? 0.3 : 1,
            "&:hover": {
              backgroundColor: "rgba(111, 111, 111, 0.12)",
              transform: "scale(1.2)",
            },
          }}
        >
          <LeftArrow />
        </IconButton>

        <Divider orientation="vertical" flexItem className="paginationDividerMargin" />

        <IconButton
          disabled={validPage === totalPages || disabled}
          onClick={handleNextPage}
          sx={{
            display: "flex",
            padding: "12px",
            alignItems: "center",
            alignSelf: "stretch",
            opacity: (validPage === totalPages || disabled) ? 0.3 : 1,
            "&:hover": {
              backgroundColor: "rgba(111, 111, 111, 0.12)",
              transform: "scale(1.2)",
            },
          }}
        >
          <RightArrow />
        </IconButton>
      </div>
    </Box>
  );
};

export default CommonPagination;
