export const ROLES = {
    SUPER_ADMIN: 'Super administrator',
    ORG_ADMIN: 'Organization administrator'
  };
  
  export const RESOURCES = {
    HOME: 'Home',
    ALARMS: 'Alarms',
    SETTINGS: 'Settings',
    AUDIT_LOG: 'AuditLog',
    SIGN_OUT: 'Signout', 
  };
  
  export const ACTIONS = {
    VIEW: 'VIEW',
    EDIT: 'EDIT',
    ACKNOWLEDGE: 'ACKNOWLEDGE'
  };
  
  export const ROLE_PERMISSIONS = {
    [ROLES.SUPER_ADMIN]: {
      name: 'Super administrator',
      permissions: [
        { resource: RESOURCES.HOME, actions: [ACTIONS.VIEW, ACTIONS.EDIT] },
        { resource: RESOURCES.ALARMS, actions: [ACTIONS.VIEW, ACTIONS.ACKNOWLEDGE] },
        { resource: RESOURCES.SETTINGS, actions: [ACTIONS.VIEW, ACTIONS.EDIT] },
        { resource: RESOURCES.SIGN_OUT, actions: [ACTIONS.VIEW] }
      ]
    },
    [ROLES.ORG_ADMIN]: {
      name: 'Organization administrator',
      permissions: [
        { resource: RESOURCES.HOME, actions: [ACTIONS.VIEW] },
        { resource: RESOURCES.ALARMS, actions: [ACTIONS.VIEW, ACTIONS.ACKNOWLEDGE] },
        { resource: RESOURCES.SETTINGS, actions: [] },
        { resource: RESOURCES.SIGN_OUT, actions: [ACTIONS.VIEW] }
      ]
    }
  };