/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Grid } from "@mui/material";
import Lottie from "lottie-react";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "../../App.scss";
import AlarmHome from "./../alarm/AlarmHome";
import AuditHome from "./../audit/AuditHome";
import "../langing-page/LandingPages.scss";
import CustomCircularProgress from "../../common/reusable-comps/circular-progress/CircularProgress";
import SettingTabs from "../setting/SettingTabs";
import LandingPage from "../langing-page/Index";
import EmptyLanding from "../langing-page/EmptyLanding";
import TopNavigation from "../top-common-navigation/Index";
import useFetch from "../../common/hook/useFetch";
import { prefectures } from "../setting/CustomerData";
import {
  setAllCustomers,
  setMyFavoriteLists,
  setMyRecentSearchList,
  setSelectedCategory,
  setSelectedAreas,
  setSelectedSites, setRecentSearchId, setUpdateBeadcrumbs
} from "../../redux/slices/customerFilterSlice";
import { getSavedFavorite, getRecentSearch, getHomeAllSiteList, customerListURL, recentSearchURL } from "../../services/apiConfig";
import usePost from "../../common/hook/usePost";
import { useAuth } from "../../common/utils/AuthContext";
import { ROLES } from "../../common/utils/Permissions";
import NoSite from "./NoSite";
import spinner from "../../assets/animation/spinner.json";


const CenterContent = ({ type, user }) => {
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const { loading } = useSelector((state) => state?.loginInfo);
  const dispatch = useDispatch();
  const { allCustomers, isDefaultDeleted, myFavoriteLists, recentSearchId } = useSelector((state) => state?.customer);
  const { accordionList } = useSelector((state) => state?.customerInfo);
  const userId = localStorage.getItem("userId");
  const [hasEffectRun, setHasEffectRun] = useState(false); // Change from useRef to useState
  const [nocustomer, setNoCustomer] = useState(false);
 
const [noSite, setnoSite]=useState(false);

  const usePreviousPath = () => {
    const location = useLocation();
    const previousPath = useRef(location.pathname);

    useEffect(() => {
      previousPath.current = location.pathname;
    }, [location]);

    return previousPath.current;
  };
  // Use a ref to track if the effect has run
  const hasRun = useRef(false);

  const {
    data: fetchCustomers,
    error: fetchCustomersError,
    loading: fetchCustomersLoading,
    fetchData: getCustomersList,
  } = useFetch();

  const {
    data: savedFavorite,
    error: savedFavoriteError,
    loading: savedFavoriteLoading,
    fetchData: getAllSavedFavorite,
  } = useFetch();

  const {
    data: recentSearchData,
    error: recentSearchError,
    loading: recentSearchLoading,
    fetchData: getRecentSearchData,
  } = useFetch();

  const {
    data: fetchsitesData,
    error: fetchsiteError,
    loading: fetchsiteErrorLoading,
    fetchData: getSitesDataList,
  } = useFetch();

  const {
    loading: recenSearchLoading,
    error: recenSearchError,
    data: recenSearchData,
    postData: doPostrecetSearch,
  } = usePost();
  const previousPath = usePreviousPath();
  useEffect(() => {
    if (type === "home" || type === "alarm") {
      getCustomersList(customerListURL);
      getAllSavedFavorite(getSavedFavorite(userId));
      getRecentSearchData(getRecentSearch(userId));
      dispatch(setUpdateBeadcrumbs(true));
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    if ((previousPath === "/setting" && type === "home") || (previousPath === "/setting" && type === "alarm")) {
      getCustomersList(customerListURL);
      getAllSavedFavorite(getSavedFavorite(userId));
      getRecentSearchData(getRecentSearch(userId));
      dispatch(setUpdateBeadcrumbs(true));
      setHasEffectRun(false);
    }

    if (type === "setting") {
      dispatch(setRecentSearchId(""));
    }
  }, [type]); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    if (!fetchCustomersLoading && !fetchCustomersError && fetchCustomers) {
      if (fetchCustomers?.length > 0) {
        const sortedCustomer = fetchCustomers?.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        dispatch(setAllCustomers(sortedCustomer));
        setNoCustomer(false)
      } else {
        setNoCustomer(true)
      }
    }
  }, [fetchCustomers, fetchCustomersError, fetchCustomersLoading]);

  useEffect(() => {
    if (savedFavorite && !savedFavoriteError && !savedFavoriteLoading) {
      dispatch(setMyFavoriteLists(savedFavorite));
    }
  }, [savedFavorite, savedFavoriteError, savedFavoriteLoading]);

  useEffect(() => {
    if (recentSearchData && !recentSearchError && !recentSearchLoading) {
      dispatch(setMyRecentSearchList(recentSearchData));
    }
  }, [recentSearchData, recentSearchError, recentSearchLoading]);

  useEffect(() => {
    if (savedFavorite && !savedFavoriteError && !savedFavoriteLoading && savedFavorite?.length > 0) {
      // Check for default favorite
      const defaultFavorite = savedFavorite?.find(fav => fav.defaultFavourite);

      if (defaultFavorite) {
        const states = defaultFavorite?.siteList?.filter(item => item?.isSiteDeleted === false)?.map(item => prefectures.find(pref => pref.value === item.state)?.label);
        const areas = [...new Set(states)];
        const sites = defaultFavorite?.siteList?.filter(item => item?.isSiteDeleted === false)?.map(item => ({
          id: item.siteId,
          state: prefectures.find(pref => pref.value === item.state)?.label,
          name: item.siteName,
          isSiteDeleted: item.isSiteDeleted
        }));

        dispatch(setSelectedCategory({
          id: defaultFavorite.customerId,
          name: defaultFavorite.customerName,
          displayName: defaultFavorite.customerName,
          favouriteName: defaultFavorite.favouriteName
        }));
        dispatch(setSelectedAreas(areas));
        dispatch(setSelectedSites(sites));
        const siteList = sites?.filter(item => item?.isSiteDeleted === false) // Only include sites that are deleted
          ?.map(site => ({
            siteId: site.id,
            state: prefectures?.find(pref => pref.label === site.state)?.value, // Map the state value to the prefecture label
            siteName: site.name
          }));

        const recentSearchPayload = {
          "userId": userId,
          "customerId": defaultFavorite.customerId,
          "customerName": defaultFavorite.customerName,
          "siteList": siteList,
          "lastUsed": new Date().getTime()
        };
        if (hasEffectRun) return; // Exit if the effect has already run
        setHasEffectRun(true); // Set the flag to true after the first run
        doPostrecetSearch(recentSearchURL, recentSearchPayload);
        return; // Exit early if a default favorite was dispatched
      }
    }

    if (
      (Array.isArray(savedFavorite) && savedFavorite.length > 0 && !savedFavoriteError && !savedFavoriteLoading) ||
      (Array.isArray(recentSearchData) && !recentSearchError && !recentSearchLoading)
    ) {
      // Ensure recentSearchData is an array before mapping
      const filteredRecentData = Array.isArray(recentSearchData)
        ? recentSearchData.map(item => ({
          ...item,
          siteList: item.siteList.filter(site => !site.isSiteDeleted),
        }))
        : [];


      const defaultFavorite = Array.isArray(savedFavorite) ? savedFavorite.find(fav => fav?.defaultFavourite) : null;
      if (!defaultFavorite && recentSearchData.length > 0) {
        const states = filteredRecentData[0]?.siteList
          ?.map(item => prefectures?.find(pref => pref?.value === item?.state)?.label);
        const areas = [...new Set(states)];
        const sites = filteredRecentData[0]?.siteList
          .map(item => ({
            id: item.siteId,
            state: prefectures.find(pref => pref.value === item.state)?.label,
            name: item.siteName,
            isSiteDeleted: item.isSiteDeleted
          }));
        // Check if recent search data is available
        if (filteredRecentData.length > 0) {
          const { customerId, customerName, favouriteName } = filteredRecentData[0];
  
          const payload = {
            id: customerId,
            name: customerName,
            displayName: customerName,
          };
        
          // Conditionally add 'favouriteName' if it exists
          if (favouriteName) {
            payload.favouriteName = favouriteName;
          }
        
          // Dispatch the action with the payload
          dispatch(setSelectedCategory(payload)); 
          dispatch(setSelectedAreas(areas));
          dispatch(setSelectedSites(sites));

          // To get the user Search ID
          const siteList = sites?.map(site => ({
            siteId: site.id,
            state: prefectures?.find(pref => pref.label === site.state)?.value,
            siteName: site.name
          }));
          const recentSearchPayload = {
            "userId": userId,
            "customerId": filteredRecentData[0].customerId,
            "customerName": filteredRecentData[0].customerName,
            "siteList": siteList,
            "lastUsed": new Date().getTime()
          };
          if (hasEffectRun) return; // Exit if the effect has already run
          setHasEffectRun(true); // Set the flag to true after the first run
          doPostrecetSearch(recentSearchURL, recentSearchPayload);

          return; // Exit early if a recent search was dispatched
        }

      }
    }

    if (
      savedFavorite?.length === 0 &&
      recentSearchData?.length === 0 &&
      fetchCustomers?.length > 0 && !(user?.customer && Array.isArray(user.customer) && user.customer.length > 0)
    ) {
      // Make sure the effect only runs once
      if (!hasRun.current) {
        // Fallback to fetchCustomers
        const customer = fetchCustomers[0];
        if (customer) {
          dispatch(
            setSelectedCategory({
              id: customer.id,
              name: customer.name,
              displayName: customer.displayName,
            })
          );
          if (hasEffectRun) return; // Exit if the effect has already run
          if (!fetchsiteErrorLoading && !fetchsiteError && !fetchsitesData) {
            getSitesDataList(getHomeAllSiteList(customer.id));
          }

          // Ensure fetchsitesData is defined before processing
          if (!fetchsiteErrorLoading && !fetchsiteError && fetchsitesData) {
            const states = fetchsitesData
              ?.map((item) =>
                prefectures.find((pref) => pref.value === item.state)?.label
              )
              .filter(Boolean);
            const areas = [...new Set(states)];
            const sites = fetchsitesData?.map(item => ({
              id: item.id,
              state: prefectures.find(pref => pref.value === item.state)?.label,
              name: item.name
            }));
            dispatch(setSelectedAreas(areas));
            dispatch(setSelectedSites(sites));


            // To get the user Search ID
            const siteList = sites?.map(site => ({
              siteId: site.id,
              state: prefectures?.find(pref => pref.label === site.state)?.value,
              siteName: site.name
            }));

            const recentSearchPayload = {
              "userId": userId,
              "customerId": customer.id,
              "customerName": customer.displayName,
              "siteList": siteList,
              "lastUsed": new Date().getTime()
            };
            if (sites?.length > 0) {

              setHasEffectRun(true); // Set the flag to true after the first run
              doPostrecetSearch(recentSearchURL, recentSearchPayload);
            }

          }
        }
      }
    }else if(!!user&&!!user?.customerId && user?.role === ROLES?.ORG_ADMIN){
      const customer = user?.customer[0];
      if (!hasRun.current) { 
        if (customer) {
          dispatch(
            setSelectedCategory({
              id: customer?.customerId,
              name: customer?.customerName|'',
              displayName: customer?.customerDisplayName,
            })
          );
          if (hasEffectRun) return; // Exit if the effect has already run
          if (!fetchsiteErrorLoading && !fetchsiteError && !fetchsitesData) {
            getSitesDataList(getHomeAllSiteList(customer?.customerId));
          }

          // Ensure fetchsitesData is defined before processing
          if (!fetchsiteErrorLoading && !fetchsiteError && fetchsitesData) {
            const states = fetchsitesData
              ?.map((item) =>
                prefectures.find((pref) => pref.value === item.state)?.label
              )
              .filter(Boolean);
            const areas = [...new Set(states)];
            const sites = fetchsitesData?.map(item => ({
              id: item.id,
              state: prefectures.find(pref => pref.value === item.state)?.label,
              name: item.name
            }));
            dispatch(setSelectedAreas(areas));
            dispatch(setSelectedSites(sites));


            // To get the user Search ID
            const siteList = sites?.map(site => ({
              siteId: site.id,
              state: prefectures?.find(pref => pref.label === site.state)?.value,
              siteName: site.name
            }));

            const recentSearchPayload = {
              "userId": userId,
              "customerId": customer?.customerId,
              "customerName": customer?.customerDisplayName,
              "siteList": siteList,
              "lastUsed": new Date().getTime()
            };
            if (sites?.length > 0) {

              setHasEffectRun(true); // Set the flag to true after the first run
              doPostrecetSearch(recentSearchURL, recentSearchPayload);
            }

          }
        }
      }
    }
  }, [hasEffectRun, fetchsitesData, fetchsiteError, fetchsiteErrorLoading, fetchCustomers, savedFavorite, savedFavoriteError, savedFavoriteLoading, recentSearchData, recentSearchError, recentSearchLoading]);

  useEffect(() => {
    if (!recenSearchLoading && !recenSearchError && recenSearchData) {
      dispatch(setRecentSearchId(recenSearchData?.result?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recenSearchLoading, recenSearchError, recenSearchData])

  useEffect(()=>{
    if(fetchsitesData && !fetchsiteError && !fetchsiteErrorLoading){
      if(fetchsitesData?.length==0 && (user?.role === ROLES?.ORG_ADMIN)){
        setnoSite(true)
      }else if(fetchsitesData?.length>0){
        setnoSite(false)
      }
    } 
  },[fetchsitesData,fetchsiteError,fetchsiteErrorLoading])

  useEffect(() => {
    if (type != "setting") {
      setShowAddCustomer(false);
    }
  }, [type]);

  const getUIBasedOnLocation = (typeVal) => {
    switch (typeVal) {
      case "home":
        return (
          <React.Fragment>
            {allCustomers?.length > 0 && <LandingPage type={type}/>}
            {nocustomer && (
              <EmptyLanding
                user={user}
                showAddCustomer={showAddCustomer}
                setShowAddCustomer={setShowAddCustomer}
                type={type}
              />
            )}
          </React.Fragment>
        );
      case "setting":
        return (
          <SettingTabs
            user={user}
            showAddCustomer={showAddCustomer}
            setShowAddCustomer={setShowAddCustomer}
            type={type}
          />
        );
      case "alarm":
        return <AlarmHome type={type} />;
      case "audit":
        return <AuditHome />;
      default:
        return null;
    }
  };
  return (
    <>
      {loading === "loading" && <CustomCircularProgress />}
   
      <Outlet />
      {type !== "setting" && allCustomers.length > 0 && <TopNavigation/>}
      { !fetchsitesData && !fetchsiteError && fetchsiteErrorLoading &&<div className="loading spinner-wrapper blue-spinner">
              <Lottie className="spinner" animationData={spinner} autoplay loop />
            </div>}
      <Grid
        sx={{
          height: type === "setting" ? "100%" : type === "alarm" ? "calc(100vh - 149px)" : "87%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
          { (noSite) ? <NoSite  />:getUIBasedOnLocation(type)}
 
      </Grid > 
    </>
  );
};
export default CenterContent;
