import React, { useEffect, useState, useMemo } from "react";
import { Container, Tabs, Tab, Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AlarmSummary from './AlarmSummaryTab/AlarmSummary';
import './AlarmHome.scss';
import usePost from "../../common/hook/usePost";
import AlarmLogs from './AlarmLogsTab/AlarmLogs';
import { formatToCustomDateString , convertDateTimeToTimestamp, formatDate} from "../../common/utils/CommonUtils";  
import { electricityConsumptionURL } from "../../services/apiConfig";
import { updateAccordionList } from "../../redux/slices/customerSlice";

function AlarmHome() {

  const { t } = useTranslation();
  const { alarm  } = t("mainContainer");

  const [value, setValue] = useState(0);
  const [shouldFetch, setShouldFetch] = useState(true); // Add this line
  const dispatch = useDispatch();
  const {
    data: prefectureApiData,
    error: prefectureApiError,
    loading: prefectureApiLoading,
    postData: doPostPrefectureApi,
  } = usePost();


  const { recentSearchId , DateRangeFilter } = useSelector(
    (state) => state?.customer
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setShouldFetch(false); // Prevent fetching when changing tabs
  };

  useEffect(() => {
    if (recentSearchId) {
      const [dateStartString, dateEndString] = DateRangeFilter?.value;

      // To convert a date string from the format YYYY/MM/DD HH:mm 
      const customDateStartString = formatToCustomDateString(formatDate(dateStartString));
      const customDateEndString = formatToCustomDateString(formatDate(dateEndString));

      // Create and return the timestamp 
      const recentSearchPayload = {
        searchId: recentSearchId,
        startDate: convertDateTimeToTimestamp(customDateStartString),
        endDate: convertDateTimeToTimestamp(customDateEndString)
      };
      doPostPrefectureApi(electricityConsumptionURL, recentSearchPayload); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentSearchId]);

  useEffect(() => {
    if (!prefectureApiLoading && !prefectureApiError && prefectureApiData) {
     
      dispatch(updateAccordionList(prefectureApiData?.result)); 
      
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefectureApiData, prefectureApiError, prefectureApiLoading ]);

  return   <Box className="flex-container alarmSummary">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs example"
            sx={{ borderBottom: 1, borderColor: 'divider', width: "100%"  }}
          >
            <Tab label={alarm.AlarmSummary} />
            <Tab label={alarm.Alarmlog} /> 
          </Tabs>
          <Box sx={{ p: 0 ,height:"100%",width:"100%"}}>
            {value === 0 &&   <AlarmSummary/> }
            {value === 1 &&  <AlarmLogs/> } 
          </Box>
        </Box> ;
}

export default AlarmHome;
