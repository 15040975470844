/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import RightSection from "./RightSection";
import LeftSection from "./LeftSection";
import { useNavigate } from "react-router-dom/dist";
import { NWC_HOME_URL, NWC_RESET_PASSWORD } from "../../common/constants/Constants";
import { useAuth } from "../../common/utils/AuthContext";

function LoginPage() {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  // useEffect(() => {
  //   if (accessToken) {
  //     navigate(NWC_HOME_URL);
  //   }
  // }, []);
  const { user } = useAuth();
  useEffect(() => {
    if (user && user?.passwordChangeRequired) {
      navigate(NWC_RESET_PASSWORD);
    }else if(accessToken&&!user?.passwordChangeRequired){
      navigate(NWC_HOME_URL);
    }
  }, [user]);
  return (
    <Grid container className="loginContainer">
     <LeftSection />
      <RightSection isAuthenticated={accessToken} />
    </Grid>
  );
}

export default LoginPage;
